import _encoding from "./lib/encoding.js";
var exports = {};
// This is free and unencumbered software released into the public domain.
// See LICENSE.md for more information.
var encoding = _encoding;
exports = {
  TextEncoder: encoding.TextEncoder,
  TextDecoder: encoding.TextDecoder
};
export default exports;
export const TextEncoder = exports.TextEncoder;